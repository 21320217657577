<template>
  <b-card>
    <validation-observer ref="formStudent" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(save)">
        <h5 class="mb-3">{{ model.coursename }} | {{ model.lecturername }} | {{ model.year }} | Semester {{ model.semester }}</h5>

        <b-row v-for="(quesionner, index) in quesionners" :key="quesionner.id">
          <b-col cols="12">
            <b-card style="background-color: #f3f2f7">
              <b-form-group :label="`${index + 1}. ${quesionner.name}`">
                <validation-provider
                  #default="{ errors }"
                  :name="`Pertanyaan ke ${index + 1}`"
                  rules="required"
                >
                <b-form-input v-if="quesionner.isEssay" :name="`input-${quesionner.id}`" v-model="choises[`answer${quesionner.id}`]" :state="errors.length > 0 ? false : null" placeholder="Masukan Kode Kuesioner" />
                  <b-form-radio-group
                    v-else
                    v-model="choises[`answer${quesionner.id}`]"
                    :options="quesionner.options"
                    :name="`radio-${quesionner.id}`"
                    :state="errors.length > 0 ? false : null"
                    stacked
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>

        <b-col class="text-right">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mr-1"
            @click="$router.go(-1)"
          >
            Kembali
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
          >
            Simpan
          </b-button>
        </b-col>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardText,
  BFormRadioGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      config: {
        action: this.$route.meta.action,
        api: "questionnaire-students/questions",
        apiAnswer: "questionnaire-students/answers",
        redirect: "feedback-block",
      },
      quesionners: [],
      choises: {},
      model: {},
      options: [
        { text: "Sangat Baik", value: 5 },
        { text: "Baik", value: 4 },
        { text: "Cukup", value: 3 },
        { text: "Kurang", value: 2 },
        { text: "Sangat Kurang", value: 1 },
      ],
    };
  },
  created() {
    const _ = this;
    _.loadData();
    _.model = _.$store.getters["feedbackUser/GET_QUESIONNER"];
  },
  methods: {
    loadData() {
      const _ = this;
      _.$axios.get(`${_.config.api}`).then((res) => {
        const consume = res.data;
        _.quesionners = consume.data;
      });
    },
    save() {
      const _ = this;
      const requestData = {};
      requestData.questionnaireStudentid = _.model.id;
      requestData.answers = Object.entries(_.choises).map(([key, value]) => {
        const id = key.replace("answer", "");

        return {
          questionnaireQuestionId: Number(id),
          value: value,
        };
      });

      _.$axios
        .post(`${_.config.apiAnswer}`, requestData)
        .then((res) => {
          _.$router.push({
            name: _.config.redirect,
            params: {
              variant: "success",
              text: "Isi kuesioner berhasil",
            },
          });
        });
    },
  },
};
</script>

<style></style>
